import React from "react";
import { Link } from "react-router-dom";
import "./wooRodasync.css";
import Contacto from "../../components/contacto/contacto";
import ComoFunciona from "../imagenesAplicaciones/rodasyncProducto/comoFuncionaElConector.jpg";
import ConectarConERP from "../imagenesAplicaciones/rodasyncProducto/conectarConERP.jpg";
import Ventajas from "../imagenesAplicaciones/rodasyncProducto/ventajasDeSincronizarERP.jpg";
import WooCommerceConClassicGes from "../imagenesAplicaciones/rodasyncProducto/WooCommerceConClassicGes.png";
import RodasynWooCommerce from "../imagenesAplicaciones/rodasyncProducto/RodasyncWooComerce.png";
import Plan from "../../components/plan/plan";
import WooCatalogo from "../textoPlanes/wooCatalogo";
import WooCompleto from "../textoPlanes/wooCompleto";
import WooPedidos from "../textoPlanes/wooPedidos";
import gestToWoo from "../imagenesAplicaciones/rodasyncWoo/gestToWoo.png";
import wooToGest from "../imagenesAplicaciones/rodasyncWoo/wooToGest.png";
import wooAndGest from "../imagenesAplicaciones/rodasyncWoo/wooAndGest.png";
import CookiesAcept from "../../components/cookiesAcept/cookiesAcept";
import Footer from "../../components/footer/footer";

export default function WooRodasync() {
  return (
    <div>
      <div className="wooRodasyncContainer">
        <header className="headerWooRodasync">
          <div className="presentacionWooRodasyncContainer">
            <img
              className="logoRodasync"
              src={RodasynWooCommerce}
              alt="logoRosasync"
            />
            <h1>Rodasync WooCommerce</h1>
            <h2>Conecta ClassicGes con Woocommerce</h2>
            <p>
              Es la solución de Rodapro para para la unir tu e-commerce de
              Woocommerce con tu Software de Administración ClassicGes, podrás
              añadir productos desde ClassicGes a tu tienda online y enviar
              pedidos de Woocommerce a tu software de Gestión. Trabaja menos,
              preocúpate menos y vende más.
            </p>
          </div>
          <div className="botonWooRodasyncContainer">
            <img src={WooCommerceConClassicGes} alt="wooges" />
            <div className="botonWooRodasyncButtons">
              <a href="#contacto" className="botonEmpezar">
                Contacto
              </a>
              <a href="#planesProducto" className="botonContacto">
                Empezar
              </a>
            </div>
          </div>
        </header>

        <main>
          <section className="conectarERPSectionWoo">
            <div>
              <h2>CONECTAR WOOCOMMERCE CON ERP</h2>
              <p>
                Con Rodasync podrás recibir tus pedidos en la ERP ClassicGes, es
                muy sencillo de sincronizar y recibirás la asistencia técnica
                que necesites.
              </p>
              <p>
                Funciones de Rodasync para sincronizar Woocommerce con
                ClassicGes:
              </p>
              <ul>
                <li>
                  Recibir los pedidos de la tienda online al software de
                  gestión.
                </li>
                <li>
                  Genera facturas automáticamente sobre los pedidos recibidos.
                </li>
                <li>
                  Enviar la factura al e-commerce para ponerla a disposición del
                  cliente.
                </li>
              </ul>
            </div>
            <figure>
              <img src={ConectarConERP} alt="Conecta con tu ERP" />
            </figure>
          </section>

          <section className="ventajasSectionWoo">
            <div>
              <h2>VENTAJAS DE SINCRONIZAR ERP CON WOOCOMMERCE</h2>
              <ul>
                <li>Soporte y asistencia técnica</li>
                <li>
                  Sincronización casi en tiempo real, tardamos una media de 6
                  minutos en realizar los cambios en la web, después del cambio
                  realizado en Classicges.
                </li>
                <li>Insertar Familias, categorías y subcategorías.</li>
                <li>Añadir Marcas, Tallas, Colores</li>
                <li>Sincronizar Productos y sus variaciones con ClassicGes</li>
              </ul>
            </div>
            <figure>
              <img
                src={Ventajas}
                alt="ventajas de sincronizar con Woocommerce"
              />
            </figure>
          </section>

          <section className="comoFuncionaSectionWoo ">
            <div>
              <h2>CÓMO FUNCIONA EL CONECTOR WOOCOMMERCE CON CLASSICGES</h2>
              <p>
                Tras finalizar el proceso de compra nos pondremos en contacto
                contigo antes de 48 Horas laborales, vía email o telefónica para
                comenzar la sincronización.
              </p>
              <div className="botonWooRodasyncButtons">
                <a href="#contacto" className="botonEmpezar">
                  Consultanos
                </a>
                <a href="#planesProducto" className="botonContacto">
                  Empezar
                </a>
              </div>
            </div>
            <figure>
              <img src={ComoFunciona} alt="como Funciona Rodasync" />
            </figure>
          </section>

          <section className="sincronizarSectionWoo">
            <h2>SINCRONIZAR Woocommerce CON CLASSICGES</h2>
            <p>
              Si quieres mejorar la rentabilidad de tu e-commerce y reducir el
              trabajo administrativo, sincroniza la tienda online y el ERP con
              Rodasync, el mejor software de conexión de entre Woocommerce y el
              programa de gestión ClassicGes.
            </p>
          </section>

          <section className="planesSectionWooContainer">
            <h2 id="planesProducto">PLANES Y PRECIOS</h2>
            <div className="planesContainer">
              <Plan
                id="CatalogoGestToWoo"
                titulo="Versión Catalogo"
                imagen={gestToWoo}
                precio="389 €"
                pago="pago único vitalicio"
                enlaceRodasync="https://rodapro.es/tienda/producto/rodasync-woocommerce-classicges-catalogo/"
                enlace="https://rodapro.es/tienda/producto/rodasync-woocommerce-classicges-catalogo/"
              >
                <WooCatalogo />
              </Plan>

              <Plan
                id="PedidosWooToGest"
                titulo="Versión Pedidos"
                imagen={wooToGest}
                precio="389 €"
                pago="pago único vitalicio"
                enlaceRodasync="https://rodapro.es/tienda/producto/rodasync-woocommerce-classicges-pedidos/"
                enlace="https://rodapro.es/tienda/producto/rodasync-woocommerce-classicges-pedidos/"
              >
                <WooPedidos />
              </Plan>

              <Plan
                id="CatalogoWooAndGest"
                titulo=""
                imagen={wooAndGest}
                precio="700 €"
                pago="pago único vitalicio"
                enlaceRodasync="https://rodapro.es/tienda/producto/rodasync-woocommerce-classicges-completa/"
                enlace="https://rodapro.es/tienda/producto/rodasync-woocommerce-classicges-completa/"
              >
                <WooCompleto />
              </Plan>
            </div>
          </section>
        </main>
      </div>

      <Contacto />
      <Footer />
      <CookiesAcept />
    </div>
  );
}
