import React from "react";
import ordenador from "./imagenesSistemasDeGestion/ordenadorSistemasDeGestion.png";
import carpetaLante from "./imagenesSistemasDeGestion/carpetaLante.png";
import carpetaTras from "./imagenesSistemasDeGestion/carpetaTras.png";
import documento from "./imagenesSistemasDeGestion/documento.png";
import pantalla from "./imagenesSistemasDeGestion/pantallaSistemasDeGestion.png";
import tpv from "./imagenesSistemasDeGestion/tpv.png";
import "./imagenSistemasDeGestion.css";

export default function ImagenSistemasDeGestion() {
  return (
    <figure className="containerImagen">
      <div className="containerImagenesSistemasDeGestion">
        <div id="mascaraSistemasDeGestion"></div>
        <img className="imagenesSistemasDeGestion" id="ordenadorSistemasDeGestion" alt="ordenadorSistemasDeGestion" src={ordenador}/>
        <img className="imagenesSistemasDeGestion" id="pantallaSistemasDeGestion" alt="pantallaSistemasDeGestion" src={pantalla}/>
        <img className="imagenesSistemasDeGestion" id="tpv" alt="tpv" src={tpv}/>
        <img className="imagenesSistemasDeGestion" id="carpetaTras" alt="carpetaTras" src={carpetaTras}/>
        <img className="imagenesSistemasDeGestion" id="documento" alt="documento" src={documento}/>
        <img className="imagenesSistemasDeGestion" id="carpetaLante" alt="carpetaLante" src={carpetaLante}/>
      </div>
    </figure>
  );
}
