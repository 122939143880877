import React from "react";
import { Link } from "react-router-dom";
import "./plan.css";

export default function Plan(props) {
  function redireccionarTienda() {
    window.location.href = props.enlace;
  }

  return (
    <div className="planContainer">
      <header className="cabeceraPlan">
        <figure>
          <img className="imagenPlan" src={props.imagen} alt="plan" />
        </figure>
        <h2>{props.titulo}</h2>
      </header>

      <div>{props.children}</div>

      <div className="precioPlan">{props.precio}</div>

      <div className="pagoPlan">{props.pago}</div>

      <div className="botonesContainer">
        <button className="botonComprar" onClick={redireccionarTienda}>
          Comprar
        </button>
        <button className="botonSaber" onClick={redireccionarTienda}>
          Saber más
        </button>
      </div>
    </div>
  );
}
