import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import "./navBar.css";
import rodaproLogoBlanco from "./rodaproLogoBlanco.svg";

export default function NavBar() {
  const [showEasterEgg, setShowEasterEgg] = useState(false);

  function iraServicios() {
      window.location.href = "#ContainerApartados";
  }

  function irContacto() {
      window.location.href = "#contacto";
  }

  function desplegarNavbar(){
    if(window.innerWidth < 800){
      let navBarContainer = document.getElementById("navbar");
      let menuNavBar = document.getElementById("menuNavBar");
      if(navBarContainer.style.height == "10vh" || navBarContainer.style.height == ""){
        console.log("Entra");
        navBarContainer.style.height = "80vh";
        menuNavBar.style.display = "flex";
      }else{
        navBarContainer.style.height = "10vh";
        menuNavBar.style.display = "none";
      }
    }
  }

  function abrirFeder(){
    let foto = document.getElementById("fotoReaccionaContainer").style;
    if (foto.height === "85vh"){
      foto.height = "0";
    }else{
      foto.height = "85vh";
    }
  }

  document.addEventListener("keydown", function (zEvent) {
    if (zEvent.ctrlKey && zEvent.altKey && zEvent.key === "a") {
      setShowEasterEgg(true);
      console.log(showEasterEgg);
    }
  });

  document.addEventListener("keydown", function (zEvent) {
    if (zEvent.ctrlKey && zEvent.altKey && zEvent.key === "d") {
      setShowEasterEgg(false);
      console.log(showEasterEgg);
    }
  });

  return (
    <header className="navBarContainer" id="navbar">
      <div className="LogoRodapro">
      <Link className="navBarLink" to="/">
        <img  src={rodaproLogoBlanco} alt="LogoRodapro" />
      </Link>
        <div className="MenuNavBar-Hamburguesa" onClick={desplegarNavbar}>
          <div className="MenuNavBar-Hamburguesa-Linea"></div>
          <div className="MenuNavBar-Hamburguesa-Linea"></div>
          <div className="MenuNavBar-Hamburguesa-Linea"></div>
        </div>
      </div>
      <div className="MenuNavBar" id="menuNavBar">
        <div className="MenuNavBar-OptionNavBar" onClick={abrirFeder}>
          FEDER
        </div>
        
        <div className="MenuNavBar-OptionNavBar" onClick={iraServicios}>
          Servicios
        </div>
        <div className="MenuNavBar-OptionNavBar" onClick={irContacto}>
          Contacto
        </div>
      </div>
    </header>
  );
}
