import React from "react";
import "./apartadoImagen.css";
import "../imagenes/desarrolloDeAplicaciones/imagenDesarrolloDeAplicaciones.css";

export default function ApartadoImagen(props) {

  return (
    <article className="containerApartadoImagen lalala" >
      <div className="imagen" id="imagen">
        <div>{props.children}</div>
      </div>
      <div className="texto" id="texto">
        <h2>{props.titulo}</h2>
        <p id="parrafoApartadoImagen">{props.texto}</p>
      </div>
    </article>
  );
}
