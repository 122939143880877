import React from "react";
import { Link } from "react-router-dom";
import Contacto from "../../components/contacto/contacto";

export default function Privacidad() {
  return (
    <div className="cookiesPageContainer">
      <header className="navBarContainer" id="navbar">
        <Link className="LogoRodaproCookies" to={"/"}>
            <h1>RODAPRÓ</h1>
        </Link>
      </header>
      <main>
        <h2>DOCUMENTO DE POLÍTICAS DE PRIVACIDAD.</h2>
        <h3>CONTENIDO Y OBJETIVO.</h3>
        <p>
          En el presente documento reflejamos fielmente, nuestra nueva política
          de seguridad en cuanto al tratamiento de datos personales, el uso que
          hacemos de ellos, las diferentes vías de recopilación, la base
          legitimadora del tratamiento, la comunicación de datos, y todas
          aquellas acciones que tengan como objeto el tratamiento de datos de
          carácter personal.
        </p>
        <p>
          Para una exposición más clara y mejor comprensión, se ha dividido en
          los siguientes puntos:
        </p>

        <ul>
          <li>QUIENES SOMOS.</li>
          <li>CANALES DE RECOPILACIÓN DE DATOS.</li>
          <li>QUÉ INFORMACIÓN RECOPILAMOS Y CÓMO.</li>
          <li>PROPÓSITO DEL PROCESAMIENTO DE DATOS PERSONALES.</li>
          <li>COMPARTIR DATOS PERSONALES.</li>
          <li>LAS COOKIES Y SU OBJETO.</li>
          <li>ENLACES A OTROS SITIOS WEB.</li>
          <li>RETENCIÓN Y ELIMINACIÓN.</li>
          <li>NUESTRA OBLIGACIÓN LEGAL DE DIVULGAR INFORMACIÓN.</li>
          <li>SUS DERECHOS.</li>
          <li>NUESTRO COMPROMISO.</li>
          <li>CONTACTO.</li>
        </ul>
        <h3>QUIENES SOMOS.</h3>
        <p>
          Rodapro, S.L.es una empresa de distribución y venta, cuya actividad
          principal está dirigida a empresas y profesionales, y se centra en
          neumáticos de diferentes marcas y modelos.
        </p>
        <p>
          Para Rodapro, S.L. la seguridad y privacidad de nuestros clientes,
          proveedores, colaboradores y visitantes de nuestra página web, son de
          la mayor importancia, y por ello nos comprometemos a proteger los
          datos que comparten con nosotros.
        </p>
        <p>
          Esta política de privacidad, explica cómo procesamos la información,
          que se puede utilizar directa o indirectamente, para identificar los
          datos personales individuales recopilados.
        </p>
        <h3>CANALES DE RECOPILACIÓN DE DATOS.</h3>
        <p>
          Existen diferentes vías por las cuales nos puede brindar la
          información. A través del uso de nuestros sitios web, de nuestras
          encuestas de satisfacción, de los contratos de prestación de
          servicios, de nuestros presupuestos de venta, de nuestros albaranes de
          venta, de las campañas de marketing o de similares acciones
          comerciales, como pueden ser los boletines informativos que
          periódicamente enviamos a nuestros clientes.
        </p>
        <h3>QUÉ INFORMACIÓN RECOPILAMOS Y CÓMO.</h3>
        <p>
          Rodapro, S.L. recopila, registra y analiza la información que nos
          aporta de forma voluntaria por alguno de los canales antes indicados.
          Aun siendo usted mismo quien nos brinda la información, le enviamos un
          mail de confirmación, informando y solicitando nuevamente su
          confirmación de suscripción.
        </p>
        <p>
          Mediante los contratos de servicios, la información que recopilamos,
          es cualquier información personal que nos proporcione, como lo son
          nombre y apellido, nombre de la compañía para la que trabaja, número
          de documento de identidad, código de identificación fiscal, correo
          electrónico particular y/o profesional, número de teléfono particular
          y/o profesional, su puesto y actividad en la empresa que trabaja, sus
          áreas de interés en la gama de productos y servicios de Rodarpo, S.L.
          la relación que mantenemos con usted y la forma de pago elegida,
          dependiendo de la cual se le solicita que cubra el documento SEPA con
          sus datos bancarios.
        </p>
        <p>
          A mayores, en nuestro sitio web registramos su dirección de IP y
          utilizamos “cookies”, que nos permiten reconocerlo a usted y su
          configuración preferida, o evitarle volver a ingresar información en
          visitas posteriores. Esas cookies se graban localmente en su equipo y
          son chequeadas cuando retorna a la web en el futuro. La mayoría de los
          navegadores pueden ser programados para rechazar o advertirle antes de
          descargar las cookies. La información al respecto puede encontrarse en
          la función de "ayuda" de su navegador, al igual que una explicación
          más extendida se encuentra en el apartado “Las cookies y su objeto”.
        </p>
        <p>
          Rodarpo, S.L.reúne datos sobre las visitas al sitio web, incluido el
          número de visitantes y las visitas unitarias de un mismo visitante, el
          tiempo que navega en nuestras páginas, cuales visita y de donde
          provienen los visitantes.
        </p>
        <p>
          En el caso del uso meramente informativo del sitio web, es decir, si
          no se registra o nos proporciona información, solo recopilaremos los
          datos personales que su navegador transmita a nuestro servidor, que es
          técnicamente necesaria para que le mostremos nuestro sitio web y para
          brindarle estabilidad y seguridad (la base legal es el Art. 6, párrafo
          1, frase 1, letra f, GDPR):
        </p>
        <ul>
          <li>Dirección IP.</li>
          <li>Fecha y hora de la solicitud.</li>
          <li>
            Diferencia de zona horaria a la hora del meridiano de Greenwich
            (GMT).
          </li>
          <li>Contenido de la solicitud (página concreta).</li>
          <li>Estado de acceso / código de estado HTTP.</li>
          <li>Cantidad transmitida de datos.</li>
          <li>Sitio web.</li>
          <li>De donde proviene la solicitud.</li>
          <li>Navegador utilizado.</li>
          <li>Sistema operativo y su interfaz.</li>
        </ul>
        <p>
          Finalmente, las otras vías de comunicación que entablamos con los
          clientes, recordemos que son encuestas, campañas de marketing,
          boletines informativos y similares, éstas son acciones que ya parten
          de su anterior consentimiento de tratamiento, a las cuales puede
          oponerse cuando lo desee y será tramitado de forma inmediata. Sirva
          como muestra un ejemplo, si nos solicita que no desea recibir más
          nuestro boletín informativo, a partir del momento de recepción de la
          oposición por su parte, no volverá a recibir ningún otro boletín, en
          su lugar le remitimos un correo electrónico informándole de las otras
          vías de comunicación a las que se encuentra suscrito, y le recordamos
          que sobre ellas también puede ejercer sus derechos.
        </p>
        <h3>PROPÓSITO DEL PROCESAMIENTO DE DATOS PERSONALES.</h3>
        <p>
          Rodapro utiliza los datos recopilados para comunicarse con los
          clientes, y para mejorar la web corporativa al analizar cómo los
          visitantes navegan en la misma.
        </p>
        <p>
          Al enviar una consulta a través de los formularios de nuestro sitio
          web, los visitantes pueden proporcionar información como nombre,
          nombre de la empresa, correo electrónico, dirección, teléfono y otros
          datos relevantes.
        </p>
        <p>
          Rodapro, S.L., utiliza esta información para identificar al Cliente y
          proporcionarle la información, el soporte y los servicios solicitados,
          y para cumplir con las obligaciones contractuales.
        </p>
        <p>
          Al registrarse en nuestro boletín informativo, con la finalidad de
          recibir más información sobre nuestros servicios y productos, los
          visitantes pueden proporcionar información como nombre, nombre de la
          empresa, correo electrónico, dirección, teléfono, interés del producto
          y otros datos relevantes.
        </p>
        <p>
          Rodapro, S.L., utiliza esta información para identificar al Cliente y
          proporcionarle la información, el soporte y los servicios solicitados,
          y cumplir con las obligaciones contractuales, así como contactar al
          cliente con boletines informativos, correos y acciones de ventas y
          marketing.
        </p>
        <h3>COMPARTIR DATOS PERSONALES.</h3>
        <p>
          Rodapro, S.L., con cualquier información o datos que puedan
          identificarlo directa o indirectamente, no los cede ni comparte con
          terceros. Ya sean proveedores de servicios, empresas subcontratadas,
          distribuidores, revendedores o similares. La información que usted nos
          aporta únicamente es tratada por nuestra empresa y su personal. Los
          datos son suyos y los comparte con nosotros para la finalidad de
          prestarle un mejor servicio.
        </p>
        <h3>LAS COOKIES Y SU OBJETO.</h3>
        <p>
          Además de los datos mencionados anteriormente, las cookies se
          almacenan en su equipo cuando navega en nuestro sitio web. Esos
          pequeños archivos de texto se almacenan en su disco duro y se asignan
          al navegador que está utilizando para visualizar nuestra web. Estas
          cookies no pueden ejecutar programas o transmitir virus, simplemente
          sirven para hacer que Internet sea más fácil de usar y efectivo.
        </p>
        <p>
          Este sitio web utiliza los siguientes tipos de cookies, cuyo alcance y
          funcionamiento se explican a continuación:
        </p>
        <ul>
          <li>
            Cookies transitorias, se eliminan automáticamente cuando cierra su
            navegador. Estos incluyen las cookies de sesión, en particular, que
            guardan una llamada ID de sesión con la que se pueden asignar las
            diferentes consultas de su navegador a una sesión. En consecuencia,
            su equipo puede reconocer si regresa a nuestro sitio web. Las
            cookies de sesión se eliminan si cierra sesión o cierra su
            navegador.
          </li>
          <li>
            Cookies persistentes, se eliminan automáticamente después de un
            período de tiempo específico variable. Puede eliminar las cookies en
            la configuración de seguridad de su navegador en cualquier momento.
            Puede configurar la configuración de su navegador según sus deseos
            y, por ejemplo, rechazar la aceptación de cookies de terceros o de
            todas las cookies.
          </li>
        </ul>
        <p>
          Usamos cookies para identificarlo en futuras visitas, así si tiene una
          cuenta con nosotros, se evita volver a iniciar sesión para cada
          visita. Puede evitar el uso de objetos de almacenamiento configurando
          el modo privado de su navegador, además, le recomendamos que elimine
          periódicamente sus cookies y el historial del navegador.
        </p>
        <h3>ENLACES A OTROS SITIOS WEB.</h3>
        <p>
          Al visitar nuestro sitio, los visitantes pueden seguir enlaces a otros
          sitios que están fuera de nuestro control, que son links a páginas web
          seguras de nuestros socios tecnológicos o de nuestros clientes.
          Rodapro, S.L., no es responsable del contenido o la política de
          privacidad de estos otros sitios. Periódicamente realizamos un chequeo
          de esos enlaces, para verificar que aún están activos y funcionan
          correctamente.
        </p>
        <h3>RETENCIÓN Y ELIMINACIÓN.</h3>
        <p>
          Toda la información personal recopilada por Rodapro, S.L., a través de
          sus formularios de consulta en el sitio web se almacenará en nuestros
          equipos bajo un almacenamiento seguro conforme a nuestra política de
          seguridad, al igual que si acordó agregarse a nuestra base de datos
          para recibir más información sobre productos y marketing.
        </p>
        <p>
          Los datos aportados no serán retenidos más de lo necesario para
          cumplir con los fines para los que fue recopilado o según lo exijan
          las leyes o regulaciones aplicables. Tras un periodo de inactividad
          procedemos a su eliminación, que normalmente es de 3 años, es posible
          que retengamos algunos datos personales para cumplir con nuestras
          obligaciones legales o reglamentarias o con fines estadísticos o
          históricos.
        </p>
        <p>Bajo el RGPD, tiene derecho a:</p>
        <ul>
          <li>
            Solicitar una copia de cualquier información que tengamos
            actualmente sobre usted. La cual le será aportada, en un formato que
            pueda utilizarla, como puede ser el block de notas que incorpora
            Windows en todas sus versiones, o en formato de hoja de cálculo, o
            en otro formato que nos solicite.
          </li>
          <li>
            Solicitar la rectificación o eliminación de su información de
            nuestra base de datos. Nuestro deseo es tener siempre la información
            actualizada para mantener el contacto.
          </li>
          <li>
            A cancelar su suscripción a la lista de correo a la que se haya
            registrado anteriormente.
          </li>
          <li>
            A solicitar una restricción del procesamiento de sus datos
            personales.
          </li>
          <li>
            A oponerse al procesamiento y el derecho a la portabilidad de datos.
          </li>
        </ul>
        <p>
          También tiene derecho a quejarse ante una autoridad supervisora de
          protección de datos sobre el procesamiento de sus datos personales a
          través de nosotros.
        </p>
        <p>
          Al final de este documento de Políticas de Privacidad, se le indican
          las vías de comunicación.
        </p>
        <h3>NUESTRA OBLIGACIÓN LEGAL DE DIVULGAR INFORMACIÓN.</h3>
        <p>
          Revelaremos la información personal de un cliente sin su autorización
          previa, solo cuando tengamos motivos para creer que la divulgación de
          esta información es necesaria para establecer la identidad de,
          contactar o iniciar un proceso legal contra una persona o personas
          sospechosas de la violación de los derechos o propiedad intelectual,
          ya sea de Rodapro, S.L., o de otros que podrían verse perjudicados por
          las actividades del usuario o de personas que podrían (deliberadamente
          o no) transgredir estos derechos y propiedades.
        </p>
        <p>
          De la misma forma revelaremos la información personal cuando las
          autoridades nos lo soliciten.
        </p>
        <h3>SUS DERECHOS.</h3>
        <p>
          Para que usted mismo controle sus datos de carácter personal, le
          informamos de cuáles son sus derechos:
        </p>
        <h4>Derecho de información.</h4>
        <p>
          Tiene derecho a obtener información clara, transparente y fácil de
          entender sobre la forma en que usamos sus datos personales y sobre sus
          derechos. Le facilitamos dicha información en esta Política.
        </p>
        <h4>Derecho de acceso.</h4>
        <p>
          Tiene derecho a acceder a los datos personales que usted nos
          proporcionó sin coste alguno.
        </p>
        <p>
          Las solicitudes manifiestamente infundadas, excesivas o repetitivas
          podrán no ser atendidas.
        </p>
        <p>
          Para ejercer este derecho, diríjase al último punto denominado “más
          información”.
        </p>
        <h4>Derecho de rectificación.</h4>
        <p>
          Tiene derecho a hacer que se rectifiquen sus datos personales cuando
          sean inexactos, hayan dejado de ser válidos, o a hacer que se
          completen cuando sean incompletos.
        </p>
        <p>
          Para ejercer este derecho, diríjase al último punto “más información”.
        </p>
        <h4>Derecho de supresión/olvido.</h4>
        <p>
          En determinados casos, le corresponde el derecho a hacer que sus datos
          personales sean borrados o eliminados. Es preciso señalar que no se
          trata de un derecho absoluto, puesto que podremos tener motivos
          legales o legítimos para conservarlos. Si desea que suprimamos sus
          datos personales, diríjase al último punto “más información”.
        </p>
        <h4>Derecho de oposición al marketing directo.</h4>
        <p>
          Puede darse de baja de nuestras comunicaciones de marketing directo en
          cualquier momento, la forma más fácil es responder a cualquier correo
          electrónico o comunicación que le enviemos indicando que quiere darse
          de baja. Si no, póngase en contacto con nosotros por cualquiera de los
          medios indicados en el último punto “más información”.
        </p>
        <h4>De retirar el consentimiento.</h4>
        <p>
          Puede retirar su consentimiento al tratamiento de sus datos personales
          cuando el tratamiento esté basado en su consentimiento. Si desea
          retirar su consentimiento, diríjase al último punto “más información”.
        </p>
        <h4>Derecho de reclamar.</h4>
        <p>
          Tiene derecho a reclamar ante la Agencia Española de Protección de
          Datos las prácticas de privacidad y protección de datos de Rodapro,
          S.L., No dude en ponerse en contacto con nosotros por cualquiera de
          los medios indicados en el último punto “más información”, antes de
          presentar una reclamación ante la autoridad competente en materia de
          protección de datos.
        </p>
        <h4>De limitación del tratamiento.</h4>
        <p>
          Tiene derecho a solicitar la limitación del tratamiento de sus datos.
          Si ejerce este derecho, el tratamiento de sus datos estará sujeto a
          limitaciones, por lo que podremos almacenarlos, pero no podremos
          seguir usándolos ni tratándolos. Este derecho solo puede ejercerse en
          determinadas circunstancias definidas por el Reglamento General de
          Protección de Datos, como sigue:
        </p>
        <ul>
          <li>
            Que el interesado impugne la exactitud de los datos personales,
            durante el plazo que permita al responsable verificar la exactitud
            de los mismos;
          </li>
          <li>
            Que el tratamiento sea ilícito y el interesado se oponga a la
            supresión de los datos personales y solicite en su lugar la
            limitación de su uso;
          </li>
          <li>
            Que el responsable ya no necesite los datos personales para los
            fines del tratamiento, pero el interesado los necesite para la
            formulación, el ejercicio o la defensa de reclamaciones;
          </li>
          <li>
            Que el interesado se haya opuesto al tratamiento en virtud del
            artículo 21, apartado 1, mientras se verifica si los motivos
            legítimos del responsable prevalecen sobre los del interesado.
          </li>
        </ul>
        <p>
          No dude en ponerse en contacto con nosotros, por cualquiera de los
          medios indicados en el último punto “más información”.
        </p>
        <h4>Derecho a la portabilidad de los datos.</h4>
        <p>
          Tiene derecho a trasladar, copiar o transferir datos desde nuestra
          base de datos a otra distinta. Solo es posible ejercer este derecho
          con respecto a datos que haya facilitado, cuando el tratamiento esté
          basado en la ejecución de un contrato, o en su consentimiento y el
          tratamiento se realice por medios automatizados. Las bases legales
          para tratar sus datos personales son: el consentimiento, el establecer
          una relación contractual, el interés legítimo y el cumplimiento del
          RGPD (obligación legal). Si desea ejercitar este derecho, diríjase al
          último punto “más información”.
        </p>
        <h4>De desactivación de cookies.</h4>
        <p>
          La configuración de los navegadores de Internet suele estar programada
          por defecto para aceptar Cookies, pero pueden desactivarse fácilmente
          cambiando la configuración del navegador. Para limitar o bloquear
          todas las cookies establecidas por nuestra web (lo que podría
          impedirle usarla) puede hacerlo a través de la configuración de su
          navegador.
        </p>
        <h3>NUESTRO COMPROMISO.</h3>
        <p>
          Rodapro, S.L., respeta su privacidad y se preocupa de que la seguridad
          este incorporada en todo lo que hacemos, solo tratamos sus datos para
          ofrecerle un mejor servicio. Nunca realizamos acciones que no haya
          aprobado o consentido, y si cambia de opinión solo debe indicárnoslo,
          que a la mayor brevedad procederemos.
        </p>
        <p>
          Rodapro, S.L., no vende sus datos y cuando los comparte con nosotros
          están seguros y protegidos.
        </p>
        <h3>MÁS INFORMACIÓN.</h3>
        <p>
          Si tiene más preguntas con respecto a nuestra política de privacidad,
          o desea recibir más información, no dude en ponerse en contacto:
        </p>
        <ul>
          <li>
            Enviando sus datos de contacto a nuestra dirección postal; Rodapro,
            S.L. Camiño da Veiguiña,38 – 36212 VIGO (Pontevedra)
          </li>
          <li>Mediante el correo electrónico info@rodapro.com</li>
        </ul>
        <p>
          La última actualización de nuestra Política de Privacidad ha sido en
          Enero de 2022.
        </p>
      </main>
      <Contacto />
    </div>
  );
}
