import React from "react";
import "./prestaRodasync.css";
import Contacto from "../../components/contacto/contacto";
import ComoFunciona from "../imagenesAplicaciones/rodasyncProducto/comoFuncionaElConector.jpg";
import ConectarConERP from "../imagenesAplicaciones/rodasyncProducto/conectarConERP.jpg";
import Ventajas from "../imagenesAplicaciones/rodasyncProducto/ventajasDeSincronizarERP.jpg";
import PrestashopConClassicGes from "../imagenesAplicaciones/rodasyncProducto/PrestashopConClassicGes.png";
import RodasynPrestashop from "../imagenesAplicaciones/rodasyncProducto/RodasyncPrestashop.png";
import Plan from "../../components/plan/plan";
import PrestaShopCatalogo from "../textoPlanes/prestaShopCatalogo";
import PrestaShopCompleto from "../textoPlanes/prestaShopCompleto";
import PrestaShopPedidos from "../textoPlanes/prestaShopPedidos";
import prestaAndGest from "../imagenesAplicaciones/rodasyncPresta/prestaAndGest.png";
import gestToPresta from "../imagenesAplicaciones/rodasyncPresta/gestToPresta.png";
import prestaToGest from "../imagenesAplicaciones/rodasyncPresta/prestaToGest.png";
import CookiesAcept from "../../components/cookiesAcept/cookiesAcept";
import Footer from "../../components/footer/footer";

export default function PrestaRodasync() {
  return (
    <div>
    <div className="wooRodasyncContainer">
      <header className="headerWooRodasync">
        <div className="presentacionWooRodasyncContainer">
          <img className="logoRodasync" src={RodasynPrestashop} alt="logoRosasyncPrestaShop" />
          <h1>Rodasync Prestashop</h1>
          <h2>Conecta ClassicGes con Prestashop</h2>
          <p>
            Es el programa de Rodapro para para la unir tu tienda online de
            Prestashop con tu Aplicación Administrativa ClassicGes, podrás
            añadir productos desde ClassicGes a tu e-commerce y enviar
            pedidos de Prestashop a tu software de Administración. Trabaja menos tiempo,
            preocúpate menos y aumenta tus ventas.
          </p>
        </div> 
        <div className="botonWooRodasyncContainer">
          <img src={PrestashopConClassicGes} alt="PrestaGes"/>
          <div className="botonWooRodasyncButtons">
            <a href="#contacto" className="botonEmpezar">
              Contacto
            </a>
            <a href="#planesProducto" className="botonContacto">
              Empezar
            </a>
          </div>
        </div>
      </header>

      <main>
        <section className="conectarERPSectionWoo">
          <div>
            <h2>CONECTAR PRESTASHOP CON ERP</h2>
            <p>
              Con Rodasync prestashop podrás recibir tus pedidos en el software de ClassicGes, es
              muy sencillo de sincronizar y recibirás el soporte técnico que
              necesites.
            </p>
            <p>
              Como funciona Rodasync para sincronizar Prestashop con ClassicGes:
            </p>
            <ul>
              <li>
                Ingresa los pedidos del e-commerce a ClassicGes.
              </li>
              <li>
                Genera facturas automáticamente en base a las peticiones recibidas desde tu página.
              </li>
              <li>
                Envia la factura a tu tienda online para que el cliente la tenga disponible.
              </li>
            </ul>
          </div>
          <figure>
            <img src={ConectarConERP} alt="Conecta con tu ERP con Prestashop"/>
          </figure>
        </section>

        <section className="ventajasSectionWoo">
          <div>
            <h2>VENTAJAS DE SINCRONIZAR CLASSICGES CON PRESTASHOP</h2>
            <ul>
              <li>Servicio técnico y asistencia</li>
              <li>
                Sincroniza prácticamente en tiempo real, tarda aproximadamente unos 6
                minutos en finalizar las modificaciones en tu sitio online después de ser añadido en Classicges.
              </li>
              <li>Insertar todas las categorías, subcategorías y familias</li>
              <li>Agregar Colores, Marcas y Tallas</li>
              <li>Enlaza artículos y sus variaciones con tu ERP</li>
            </ul>
          </div>
          <figure>
            <img src={Ventajas} alt="ventajas de sincronizar con prestashop" />
          </figure>
        </section>

        <section className="comoFuncionaSectionWoo ">
          <div>
            <h2>CÓMO FUNCIONA EL CONECTOR PRESTASHOP CON ERP</h2>
            <p>
              Tras Adquirir Rodasync para Prestashop nos pondremos en contacto
              contigo antes de 48 Horas laborales, vía email o telefónica para
              comenzar la sincronización entre Prestashop y ClassicGes.
            </p>
            <div className="botonWooRodasyncButtons">
              <a href="#contacto" className="botonEmpezar">
                Contacto
              </a>
              <a href="#planesProducto" className="botonContacto">
                Empezar
              </a>
            </div>
          </div>
          <figure>
            <img src={ComoFunciona} alt="funcionamiento de Rodasync Prestashop" />
          </figure>
        </section>

        <section className="sincronizarSectionWoo">
          <h2>ENLAZA PRESTASHOP CON CLASSICGES</h2>
          <p>
            Para mejorar la gestíon de tu e-commerce y reducir el
            trabajo de administrar tu e-commerce, sincroniza la tienda online y el ClassicGes con
            Rodasync para Prestashop, el mejor software de conexión entre Prestashop y el
            Software de Administración ClassicGes.
          </p>
        </section>

        <section className="planesSectionWooContainer">
          <h2 id="planesProducto">TARIFAS Y VERSIONES</h2>
          <div className="planesContainer">
          <Plan
                id="CatalogoGestToPresta"
                titulo="Rodasync Prestashop Catalogo"
                precio="389 €"
                pago="pago único vitalicio"
                enlaceRodasync="https://rodapro.es/tienda/producto/rodasync-prestashop-classicges-completa/"
                enlace="https://rodapro.es/tienda/producto/rodasync-prestashop-classicges-catalogo/"
                imagen={gestToPresta}
              >
                <PrestaShopCatalogo />
              </Plan>

              <Plan
                id="PedidosPrestaToGest"
                titulo="Rodasync Prestashop Pedidos"
                precio="389 €"
                pago="pago único vitalicio"
                imagen={prestaToGest}
                enlaceRodasync="https://rodapro.es/tienda/producto/rodasync-prestashop-classicges-pedidos/"
                enlace="https://rodapro.es/tienda/producto/rodasync-prestashop-classicges-pedidos/"
              >
                <PrestaShopPedidos />
              </Plan>

              <Plan
                id="CompletoPrestaAndGest"
                titulo=""
                precio="700 €"
                pago="pago único vitalicio"
                imagen={prestaAndGest}
                enlaceRodasync="https://rodapro.es/tienda/producto/rodasync-prestashop-classicges-completa/"
                enlace="https://rodapro.es/tienda/producto/rodasync-prestashop-classicges-completa/"
              >
                <PrestaShopCompleto />
              </Plan>
          </div>
        </section>
      </main>
    </div>
          <Contacto />
          <Footer />
          <CookiesAcept />
          </div>
  );
}
