import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import "./cookiesAcept.css";

export default function CookiesAcept() {
  const cookies = () => {
    let cookie = localStorage.getItem("aceptCookies");
    if (cookie == null) {
      localStorage.getItem("aceptCookies", false);
      return false;
    } else {
      if (cookie == "true") {
        return true;
      }
    }
  };

  const [aceptCookies, setAceptCookies] = useState(cookies());

  function aceptTheCookies() {
    setAceptCookies(true);
    localStorage.setItem("aceptCookies", true);
  }

  return (
    <div>
      {aceptCookies === false ? (
        <div className="cookiesAceptContainer">
          <p>
            Esta web usa Cookies, puedes aceptar o leer nuestra politica de
            privacidad
          </p>

          <div>
            <button className="botonAceptar">
              <Link to={"/politica-de-cookies"}>Politica de privacidad</Link>
            </button>
            <button className="botonLeer" onClick={aceptTheCookies}>
              Aceptar Cookies
            </button>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}
