import React from "react";
import ordenador from "./imagenesPosicionamientoOnline/ordenadorPosicionamientoOnline.png";
import botones from "./imagenesPosicionamientoOnline/botones.png";
import flecha from "./imagenesPosicionamientoOnline/flecha.png";
import lupa from "./imagenesPosicionamientoOnline/lupa.png";
import movil from "./imagenesPosicionamientoOnline/movilPosicionamientoOnline.png";
import pantallaMovil from "./imagenesPosicionamientoOnline/pantallaMovil.png";
import pantallaOrdenador from "./imagenesPosicionamientoOnline/pantallaordenador.png";
import "./imagenPosicionamientoOnline.css";

export default function ImagenPosicionamientoOnline() {
  return (
    <figure className="containerImagen">
      <div className="containerImagenesPosicionamientoOnline">
        <div id="mascaraPosicionamientoOnline"></div>
        <img className="imagenesPosicionamientoOnline" id="ordenadorPosicionamientoOnline" alt="ordenadorPosicionamientoOnline" src={ordenador}/>
        <img className="imagenesPosicionamientoOnline" id="pantallaOrdenador" alt="pantallaOrdenador" src={pantallaOrdenador}/>
        <img className="imagenesPosicionamientoOnline" id="botones" alt="botones" src={botones}/>
        <img className="imagenesPosicionamientoOnline" id="movilPosicionamientoOnline" alt="movilPosicionamientoOnline" src={movil}/>
        <img className="imagenesPosicionamientoOnline" id="pantallaMovil" alt="pantallaMovil" src={pantallaMovil}/>
        <img className="imagenesPosicionamientoOnline" id="flecha" alt="flecha" src={flecha}/>
        <img className="imagenesPosicionamientoOnline" id="lupa" alt="lupa" src={lupa}/>
      </div>
    </figure>
  );
}
