import React from "react";
import ordenador from "./imagenesDisenoWeb/ordenadorDisenoWeb.png";
import capa1 from "./imagenesDisenoWeb/capa1.png";
import capa2 from "./imagenesDisenoWeb/capa2.png";
import fondo from "./imagenesDisenoWeb/fondo.png";
import foto from "./imagenesDisenoWeb/foto.png";
import "./imagenDisenoWeb.css";

export default function ImagenDisenoWeb() {
  return (
    <figure className="containerImagen">
      <div className="containerImagenesDisenoWeb">
        <div id="mascaraDisenoWeb"></div>
        <img className="imagenesDisenoWeb" id="ordenadorDisenoWeb" alt="ordenadorDisenoWeb" src={ordenador}/>
        <img className="imagenesDisenoWeb" id="fondo" alt="fondo" src={fondo} />
        <img className="imagenesDisenoWeb" id="capa1" alt="capa1" src={capa1} />
        <img className="imagenesDisenoWeb" id="capa2" alt="capa2" src={capa2} />
        <img className="imagenesDisenoWeb" id="foto" alt="foto" src={foto} />
      </div>
    </figure>
  );
}
