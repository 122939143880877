import React from "react";
import "./textoPlanes.css";


export default function PrestaShopPedidos() {
  return (
    <div className="textoPlanes">
      <ul className="listaPrincipal">
        <li className="elementoPrincipal">Soporte y Servicio Técnico</li>
        <li className="elementoPrincipal">Sincroniza en tiempo real</li>
        <li className="elementoPrincipal">
          Descarga automática de pedidos de Prestashop a ClassicGes
        </li>
        <li className="elementoPrincipal">
          Genera facturas automáticamente
        </li>
        <li className="elementoPrincipal">
          Inserción de facturas desde ClassicGes a Prestashop para que el cliente las tenga disponibles.
        </li>
      </ul>
    </div>
  );
}
