import React from "react";
import ordenador from "./imagenesECommerce/ordenadorECommerce.png";
import caja from "./imagenesECommerce/caja.png";
import carrito from "./imagenesECommerce/carrito.png";
import estanteria from "./imagenesECommerce/estanteria.png";
import movil from "./imagenesECommerce/movilECommerce.png";
import pantalla from "./imagenesECommerce/pantallaECommerce.png";
import tapaEstanteria from "./imagenesECommerce/tapaEstanteria.png";
import tarjeta from "./imagenesECommerce/tarjeta.png";
import toldo from "./imagenesECommerce/toldo.png";
import "./imagenECommerce.css";

export default function ImagenECommerce() {
  return (
    <figure className="containerImagen">
      <div className="containerImagenesECommerce">
        <div id="mascaraECommerce"></div>
        <img className="imagenesECommerce" id="estanteria" alt="estanteria" src={estanteria}/>
        <img className="imagenesECommerce" id="ordenadorECommerce" alt="ordenadorECommerce" src={ordenador}/>
        <img className="imagenesECommerce" id="caja" alt="caja" src={caja}/>
        <img className="imagenesECommerce" id="tarjeta" alt="tarjeta" src={tarjeta}/>
        <img className="imagenesECommerce" id="movilECommerce" alt="movilECommerce" src={movil}/>
        <img className="imagenesECommerce" id="carrito" alt="carrito" src={carrito}/>
        <img className="imagenesECommerce" id="pantallaECommerce" alt="pantallaECommerce" src={pantalla}/>
        <img className="imagenesECommerce" id="tapaEstanteria" alt="tapaEstanteria" src={tapaEstanteria}/>
        <img className="imagenesECommerce" id="toldo" alt="toldo" src={toldo}/>
      </div>
    </figure>
  );
}
