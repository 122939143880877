import React from "react";
import { Link } from "react-router-dom";
import "./cookies.css";
import Contacto from "../../components/contacto/contacto";

export default function Cookies() {
  return (
    <div className="cookiesPageContainer">
      <header className="navBarContainer" id="navbar">
        <Link className="LogoRodaproCookies" to={"/"}>
            <h1>RODAPRÓ</h1>
        </Link>
      </header>
      <main>
        <h2>Politica de Cookies</h2>
        <div>
          <h3>CONTENIDO Y OBJETIVO.</h3>
          <p>
            En el presente documento reflejamos nuestra política de cookies,
            cuales son y que finalidad tienen. En el caso de que el usuario
            realice un uso meramente informativo del sitio web, es decir, si no
            se registra o nos proporciona información, solo recopilaremos los
            datos personales que su navegador transmita a nuestro servidor, que
            es técnicamente necesaria para que le mostremos nuestro sitio web y
            para brindarle estabilidad y seguridad (la base legal es el Art. 6,
            párrafo 1, frase 1, letra f, GDPR): -Dirección IP. -Fecha y hora de
            la solicitud. -Diferencia de zona horaria a la hora del meridiano de
            Greenwich (GMT). -Contenido de la solicitud (página concreta).
            -Estado de acceso / código de estado HTTP. -Cantidad transmitida de
            datos. -Sitio web. -De donde proviene la solicitud. -Navegador
            utilizado. -Sistema operativo y su interfaz. </p>
            <h3>LAS COOKIES Y SU
            OBJETO.</h3>
            <p>
            Además de los datos mencionados anteriormente, las cookies
            se almacenan en su equipo cuando navega en nuestro sitio web. Esos
            pequeños archivos de texto se almacenan en su disco duro y se
            asignan al navegador que está utilizando para visualizar nuestra
            web. Estas cookies no pueden ejecutar programas o transmitir virus,
            sirven para hacer que Internet sea más fácil de usar y efectivo.
            Este sitio web utiliza los siguientes tipos de cookies, cuyo alcance
            y funcionamiento se explican a continuación: - Cookies transitorias,
            se eliminan automáticamente cuando cierra su navegador. Estos
            incluyen las cookies de sesión, en particular. Las cookies de sesión
            guardan una llamada ID de sesión con la que se pueden asignar las
            diferentes consultas de su navegador a una sesión. En consecuencia,
            su equipo puede reconocer si regresa a nuestro sitio web. Las
            cookies de sesión se eliminan si cierra sesión o cierra su
            navegador. - Cookies persistentes, se eliminan automáticamente
            después de un período de tiempo específico variable. Puede eliminar
            las cookies en la configuración de seguridad de su navegador en
            cualquier momento. Puede configurar la configuración de su navegador
            según sus deseos y, por ejemplo, rechazar la aceptación de cookies
            de terceros o de todas las cookies. Usamos cookies para
            identificarlo en futuras visitas, así si tiene una cuenta con
            nosotros, se evita volver a iniciar sesión para cada visita. Puede
            evitar el uso de objetos de almacenamiento configurando el modo
            privado de su navegador, además, le recomendamos que elimine
            periódicamente sus cookies y el historial del navegador.
          </p>
          <h3>SU DERECHO DE DESACTIVACIÓN DE COOKIES.</h3>
          <p>

            La configuración de los navegadores de Internet suele estar
            programada por defecto para aceptar Cookies, pero pueden
            desactivarse fácilmente cambiando la configuración del navegador.
            Para limitar o bloquear todas las cookies establecidas por nuestra
            web (lo que podría impedirle usarla) puede hacerlo a través de la
            configuración de su navegador.
          </p>
          <h3>ENLACES A OTROS SITIOS WEB.</h3>
          <p>
            Al visitar nuestro sitio, los visitantes pueden seguir enlaces a
            otros sitios que están fuera de nuestro control, que son links a
            páginas web seguras de nuestros socios tecnológicos o de nuestros
            clientes o proveedores. Rodapro, S.L. no es responsable del
            contenido o la política de privacidad de estos otros sitios.
            Periódicamente realizamos un chequeo de esos enlaces, para verificar
            que aún están activos y funcionan correctamente.
          </p>
          <h3>POLÍTICA DE COOKIES.</h3>
          <p>
            Nuestra página Web usa cookies. Las cookies de este sitio web, se
            usan para personalizar el contenido y los anuncios. Ofrecer
            funciones de redes sociales y analizar el tráfico. Además,
            compartimos información sobre el uso que haga del sitio web con
            nuestros Partner de redes sociales, publicidad y análisis web,
            quienes pueden combinarla con otra información que les haya
            proporcionado, o que hayan recopilado a partir del uso que haya
            hecho de sus servicios. Las cookies son pequeños archivos de texto,
            que las páginas web pueden utilizar para hacer más eficiente la
            experiencia del usuario. La ley afirma que podemos almacenar cookies
            en su dispositivo, si son estrictamente necesarias para el
            funcionamiento de esta página. Para todos los demás tipos de cookies
            necesitamos su permiso. Esta página utiliza tipos diferentes de
            cookies, algunas son colocadas por servicios de terceros que
            aparecen en nuestras páginas. Su consentimiento se aplica al dominio
            “rodapro.es”
          </p>
        </div>
      </main>
      <Contacto />
    </div>
  );
}
