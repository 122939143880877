import React from "react";
import "./quienesSomos.css";

export default function QuienesSomos() {
  return (
    <section className="quienesSomos" id="quienesSomos">
      <h2>Tu empresa de desarrollo de Apps en Vigo</h2>
      <p>
        Rodapro es una empresa de software de Vigo (España) enfocada en dar
        servicio a PYMES, somos cercanos, rápidos y resolutivos. Nuestro
        objetivo es darte un software que mejore los procesos de tu empresa,
        resolver los problemas que te generan malos o anticuados programas y
        mejorar los servicios que ya tienes implementados.
      </p>

      <h2>La filosofía de Rodapro para desarrollar Software</h2>
      <p>
        Nuestra pasión por la programación y el diseño de programas y Apps es lo
        que nos define, para ello permanecemos tecnológicamente actualizados de
        todas las novedades y avances en el mundo digital. Eso resulta en
        aplicaciones más sencillas de utilizar, más potentes y más escalables
        para que tu proyecto no tenga límites
      </p>

      <h2>
        Bases de datos, lógicas de programación, servidores, dominios, hosting,
        conectividad
      </h2>
      <p>
        No te preocupes de nada de eso, en Rodapro somos expertos en backend,
        nos ocuparemos de que el mecanismo interno de tu software funcione como
        un reloj, nuestra metodología de desarrollo nos permite actualizar
        funcionalidades rápidamente y con el mejor rendimiento, estamos cerca de
        tí cuando más nos necesitas.
      </p>

      <h2>
        Paneles de control, interfaces de usuario, diseño de programas y webs
      </h2>
      <p>
        Gracias a nuestra amplia experiencia en diseños de paneles de
        administración no necesitarás complejos manuales de uso, ni constantes
        llamadas de consulta; realizamos tus interfaces con el máximo cariño y
        desde el punto de vista del usuario; la experiencia de manejo de
        software y un diseño agradable son las bases del futuro, y en ese futuro
        es donde estamos nosotros y a donde queremos llevarte.
      </p>

      <h2>Tu empresa de software</h2>
      <p>
        Si quieres trabajar menos, gastar menos y ganar más, nuestro software es
        parte de la solución, ponte en contacto con nosotros y haremos que tus
        proyectos despeguen y lleguen a lo más alto.
      </p>
    </section>
  );
}
