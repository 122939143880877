import "./App.css";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Cover from "./pages/cover";
import Cookies from "./pages/cookies/cookies";
import Privacidad from "./pages/privacidad/privacidad";
import WooRodasync from "./pages/wooRodasync/wooRodasync";
import PrestaRodasync from "./pages/prestaRodasync/prestaRodasync";
import SelbotPro from "./pages/selbotPro/selbotPro";
import HoundPro from "./pages/houndPro/houndPro";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Cover />} />
        <Route path="/politica-de-cookies" element={<Cookies />} />
        <Route path="/politica-de-privacidad" element={<Privacidad />} />
        <Route path="/sincronizar-woocommerce-con-classicges" element={<WooRodasync />}/>
        <Route path="/sincronizar-prestashop-con-classicges" element={<PrestaRodasync />}/>
        <Route path="/selbotpro-bot-para-whatsapp" element={<SelbotPro />}/>
        <Route path="/houndpro-scraping-web" element={<HoundPro />}/>
      </Routes>
    </Router>
  );
}

export default App;
