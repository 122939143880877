import React from "react";
import NavBar from "../components/navBar/navBar";
import Presentacion from "../components/presentacion/presentacion";
import ApartadoImagen from "../components/apartados/apartadoImagen/apartadoImagen";
import CasosDeExito from "../components/apartados/casosDeExito/casosDeExito";
import ApartadoTresPlanes from "../components/apartados/apartadoTresPlanes/apartadoTresPlanes";
import Contacto from "../components/contacto/contacto";
import Plan from "../components/plan/plan";
import QuienesSomos from "../components/quienesSomos/quienesSomos";
import ImagenDesarrolloDeAplicaciones from "../components/apartados/imagenes/desarrolloDeAplicaciones/imagenDesarrolloDeAplicaciones";
import ImagenSistemasDeGestion from "../components/apartados/imagenes/sistemasDeGestion/imagenSistemasDeGestion";
import ImagenDisenoWeb from "../components/apartados/imagenes/disenoWeb/imagenDisenoWeb";
import ImagenECommerce from "../components/apartados/imagenes/eCommerce/imagenECommerce";
import ImagenPosicionamientoOnline from "../components/apartados/imagenes/posicionamientoOnline/imagenPosicionamientoOnline";
import ImagenApps from "../components/apartados/imagenes/Apps/imagenApps";
import rodasyncPresta from "./imagenesAplicaciones/rodasyncPresta/rodasyncPresta.png";
import prestaAndGest from "./imagenesAplicaciones/rodasyncPresta/prestaAndGest.png";
import gestToPresta from "./imagenesAplicaciones/rodasyncPresta/gestToPresta.png";
import prestaToGest from "./imagenesAplicaciones/rodasyncPresta/prestaToGest.png";
import rodasyncWoo from "./imagenesAplicaciones/rodasyncWoo/rodasyncWoo.png";
import gestToWoo from "./imagenesAplicaciones/rodasyncWoo/gestToWoo.png";
import wooToGest from "./imagenesAplicaciones/rodasyncWoo/wooToGest.png";
import wooAndGest from "./imagenesAplicaciones/rodasyncWoo/wooAndGest.png";
import CookiesAcept from "../components/cookiesAcept/cookiesAcept";
import Footer from "../components/footer/footer";
import PrestaShopCatalogo from "./textoPlanes/prestaShopCatalogo";
import PrestaShopCompleto from "./textoPlanes/prestaShopCompleto";
import PrestaShopPedidos from "./textoPlanes/prestaShopPedidos";
import WooCatalogo from "./textoPlanes/wooCatalogo";
import WooCompleto from "./textoPlanes/wooCompleto";
import WooPedidos from "./textoPlanes/wooPedidos";
import AIG from "./imagenesAplicaciones/ClassicGes/AIG.png";
import Conta from "./imagenesAplicaciones/ClassicGes/Conta.png";
import Ges from "./imagenesAplicaciones/ClassicGes/Ges.png";
import AigConta from "./textoPlanes/AIGConta";
import AigGes from "./textoPlanes/AIGGes";
import fondo from "./imagenesAplicaciones/fondo.svg";
import "./cover.css";

export default function Cover() {

  return (
    <main className="contenidoCover">
      <NavBar />
      <div className="imagenFondo">
        <img src={fondo} alt="fondo" />
      </div>
      <div className="ContainerApartados" id="ContainerApartados">
        <div className="Apartados">
          <section className="servicesContainer">
          <div className="fotoReaccionaContainer" id="fotoReaccionaContainer" style={{height:0}}></div>
            <div id="servicesSection"></div>

            <ApartadoImagen
              titulo="Apps"
              texto="Lleva tus proyectos a otro nivel ofreciendo a tu público una App propia; fideliza clientes ofreciendo servicios exclusivos, mejora las ventas y la interacción con tus clientes, también podemos crear Apps de uso interno para empresas. En Rodapro diseñamos tu Aplicación para Smartphone sencilla de usar pero potente en sus funciones."
            >
              <ImagenApps />
            </ApartadoImagen>

            <ApartadoImagen
              titulo="Software Personalizado"
              texto="Desde programas específicos para tu sector a control de dispositivos, pasando por soluciones web, en Rodapro diseñamos la aplicación que necesitas, si tienes un problema y se puede solucionar con software seguro que podremos ayudarte, te asesoraremos y daremos soporte en cada paso."
            >
              <ImagenDesarrolloDeAplicaciones />
            </ApartadoImagen>

            <ApartadoImagen
              titulo="Diseño Web"
              texto="Podemos diseñar web llamativas, funcionales, rápidas y adaptables, te proporcionaremos todos los servicios y asesoramiento necesario, tanto si necesitas una pequeña web para presentarte a tus clientes o un entorno que proporcione una experiencia de usuario única para tus usuarios."
            >
              <ImagenDisenoWeb />
            </ApartadoImagen>

            <ApartadoImagen
              titulo="e-commerce"
              texto="Con una tienda online tu escaparate es el mundo entero, sabemos lo importante que es para tu negocio que la gestión de tu comercio electrónico sea sencilla, se adapte a tus necesidades, que puedas obtener métricas y que el usuario tenga una sensación agradable en el proceso de compra. "
            >
              <ImagenECommerce />
            </ApartadoImagen>

            <ApartadoImagen
              titulo="Posicionamiento Online"
              texto="Si quieres llevar tus ventas a otro nivel el SEO es imprescindible, conseguiremos que tu producto se presente a quien lo necesite, mejoramos las tasas de conversiones de visitas a ventas con estrategias personalizadas, te orientaremos en la dirección adecuada y analizaremos las métricas para mejorar tu posición en buscadores."
            >
              <ImagenPosicionamientoOnline />
            </ApartadoImagen>

            <ApartadoImagen
              titulo="Sistemas de Gestión"
              texto="Somos especialistas en el desarrollo de soluciones para la gestión de empresas, contabilidad, almacén, recursos humanos, comercial… así como de software de integración y sincronización entre los distintos programas de administración, dispositivos y webs de tu compañía. Conectamos las piezas para que todo funcione como un reloj."
            >
              <ImagenSistemasDeGestion />
            </ApartadoImagen>
          </section>
          <QuienesSomos />
          <Contacto />
          <Footer />
        </div>
      </div>
      <CookiesAcept />
    </main>
  );
}
