import React from "react";
import "./textoPlanes.css";

export default function PrestaShopCatalogo() {
  return (
    <div className="textoPlanes">
      <ul className="listaPrincipal">
        <li className="elementoPrincipal">Soporte y Servicio Técnico</li>

        <li className="elementoPrincipal">
          Sincronización casi en tiempo real, tarda aproximadamente 6 minutos en
          realizar los cambios en la web, después del cambio realizado en
          Classicges.
        </li>

        <li className="elementoPrincipal">
          Familias, categorías y subcategorías.
          <ul className="listaSecundaria">
            <li className="elementoSecundario">
              Todos los niveles de familias y categorías que necesites
            </li>
            <li className="elementoSecundario">
              Actualiza el nombre, la descripción y la imagen.
            </li>
          </ul>
        </li>

        <li className="elementoPrincipal">
          Marcas
          <ul className="listaSecundaria">
            <li className="elementoSecundario">
              Todas las tallas disponibles en los productos de tu ClassicGes
            </li>
            <li className="elementoSecundario">Actualiza todos los valores.</li>
          </ul>
        </li>

        <li className="elementoPrincipal">
          Tallas
          <ul className="listaSecundaria">
            <li className="elementoSecundario">
              Todas las tallas disponibles en los productos de tu ClassicGes
            </li>
            <li className="elementoSecundario">Actualiza todos los valores.</li>
          </ul>
        </li>

        <li className="elementoPrincipal">
          Colores
          <ul className="listaSecundaria">
            <li className="elementoSecundario">
              Todos los colores que estén en algún producto marcado como tienda
              online.
            </li>
          </ul>
        </li>

        <li className="elementoPrincipal">
          Productos
          <ul className="listaSecundaria">
            <li className="elementoSecundario">Sin límite de productos.</li>
            <li className="elementoSecundario">Sin límite de imágenes</li>
            <li className="elementoSecundario">Actualiza todos los valores</li>
            <li className="elementoSecundario">Ofertas de productos</li>
            <li className="elementoSecundario">Selección de campos a actualizar, para mantener el SEO</li>
          </ul>
        </li>

        <li className="elementoPrincipal">
          Variaciones o Combinaciones
        </li>
        
      </ul>
    </div>
  );
}
