import React from "react";
import "./textoPlanes.css";

export default function WooPedidos() {


  return (
    <div className="textoPlanes">
    <ul className="listaPrincipal">
      <li className="elementoPrincipal">Soporte y asistencia técnica</li>
      <li className="elementoPrincipal">Sincronización en tiempo real</li>
      <li className="elementoPrincipal">
        Descarga automática de pedidos de WooCommerce a ClassicGes
      </li>
      <li className="elementoPrincipal">
        Generación automatizada de facturas
      </li>
      <li className="elementoPrincipal">
        Subida de facturas de ClassicGes a WooCommerce a disposición del
        cliente.
      </li>
    </ul>
  </div>
  );
}
