import React from "react";
import { useState } from "react";
import AppsMovil from "./imagenesApps/appsMovil.png";
import AppsBotones1 from "./imagenesApps/appsBotones1.png";
import AppsBotones2 from "./imagenesApps/appsBotones2.png";
import AppsEstrellas from "./imagenesApps/appsEstrellas.png";
import AppsFoto from "./imagenesApps/appsFoto.png";
import AppsGlobo1 from "./imagenesApps/appsGlobo1.png";
import AppsGlobo2 from "./imagenesApps/appsGlobo2.png";
import AppsGlobo3 from "./imagenesApps/appsGlobo3.png";
import AppsSlider from "./imagenesApps/appsSlider.png";
import "./imagenApps.css";

export default function ImagenApps() {
  return (
    <figure className="containerImagen">
      <div className="containerImagenesApps">
        <div className="mascaraApps"></div>
        <img className="AppsMovil" alt="AppsMovil" src={AppsMovil}/>
        <img className="AppsBotones1" alt="AppsBotones1" src={AppsBotones1}/>
        <img className="AppsBotones2" alt="AppsBotones2" src={AppsBotones2}/>
        <img className="AppsEstrellas" alt="AppsEstrellas" src={AppsEstrellas}/>
        <img className="AppsFoto" alt="AppsFoto" src={AppsFoto}/>
        <img className="AppsSlider" alt="AppsSlider" src={AppsSlider}/>
        <img className="AppsGlobo1" alt="AppsGlobo1" src={AppsGlobo1}/>
        <img className="AppsGlobo2" alt="AppsGlobo2" src={AppsGlobo2}/>
        <img className="AppsGlobo3" alt="AppsGlobo3" src={AppsGlobo3}/>
      </div>
    </figure>
  );
}
