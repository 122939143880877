import React from "react";
import "./selbotPro.css";
import Contacto from "../../components/contacto/contacto";
import CookiesAcept from "../../components/cookiesAcept/cookiesAcept";
import Footer from "../../components/footer/footer";
import img1 from "./imgSelBotPro1.webp";
import img2 from "./imgSelBotPro2.webp";
import logoSelbotpro from "./selbotproLogo.svg";

export default function SelbotPro() {
  return (
    <div >
      <div className="selbotpro">
        <header>
          <img src={logoSelbotpro} alt="Logo SelBotPro" />
          <h1>
            Mejora el servicio de tu negocio y ahorra tiempo con tu WhatsApp
          </h1>
        </header>
      </div>
      <main className="selbotpro">
        <section className="selbotpro-clientes">
          <div>
            <h2>¿Podría un bot vender a través de Whatsapp?</h2>
            <p>
              ¡SÍ! Rodapró ha desarrollado un sistema automatizado de venta,
              ahorrarás mucho tiempo y te hará más accesible a tus clientes.
            </p>

            <h3>BÚSQUEDAS DE PRODUCTOS</h3>
            <p>
              Tus clientes podrán consultar los productos que tienes disponibles
              en tu e-commerce a través de Whastapp, esto, evita que un usuario
              que en ese momento no tiene acceso a un ordenador, no necesite
              entrar en la web, con las incomodidades que eso conlleva, con un
              simple mensaje es suficiente, el SelbotPró se encargará de mostrar
              los resultados en su aplicación de mensajería.
            </p>

            <h3>COMPRAS</h3>
            <p>
              Tus usuarios dispondrán de este servicio extra para tu tienda
              online, en el que a partir de un sencillo mensaje de Whatsapp,
              podrán realizar una compra fácilmente, este servicio, cobra
              relevancia cuando se trata de compras recurrentes o negocios B2B
              en el que las empresas o usuarios ya saben de antemano el producto
              que necesitas, de esta manera podrán adquirirlo más rápido y desde
              cualquier lugar, sin necesidad de entrar en la página web.
            </p>

            <h3>CONSULTAR ESTADO DE PEDIDOS</h3>
            <p>
              ¿Cuántas veces has atendido llamadas o mails de clientes
              preguntando por el estado de su pedido? No te preocupes más por
              esto, esta tarea queda automatizada gracias a SelbotPró. Ahorrarás
              mucho tiempo y tus clientes tendrán más tranquidad.
            </p>

            <h3>ATENCIÓN AL CLIENTE</h3>
            <p>
              Para hacer reclamaciones sobre pedidos tus usuarios dispondrán de
              un sistema más sencillo, que les permita realizarlas, podrán estar
              ligadas a un pedidos y productos y se enviarán a tu sistema de
              atención al cliente de tu tienda online.
            </p>

            <h3>SOLICITAR FACTURAS</h3>
            <p>
              Tan sencillo como eso, simplemente, si un cliente solicita por
              mensaje facturas ya sea por fecha, las últimas facturas o una
              factura de un pedido en concreto se le enviará vía Whatsapp.
            </p>
          </div>
          <img src={img2} alt="selbotpro para WhatsApp" />
        </section>
        <section className="selbotpro-administrador">
          <div>
            <h2>¿Y si pudiera gestionar mi empresa por Whatsapp?</h2>
            <p>
              ¡Claro! Sin entrar en el panel de tu tienda online o software de
              gestión podrás:
            </p>

            <h3>CREAR PRODUCTOS</h3>
            <p>
              En Rodapró sabemos lo urgente que es subir un nuevo producto a tu
              e-commerce, por ello, hemos desarrollado un sistema de diálogo a
              través de Whatsapp que te permitirá, fácilmente, crear nuevos
              productos sin tener que entrar en la página de administrador de tu
              web.
            </p>

            <h3>INFORMES DE VENTAS</h3>
            <p>
              Tendrás un resumen de las ventas por día, semana, mes y año;
              comparándolas con la jornada anterior. Dispondrás de informes por
              ingresos, por productos vendidos, por pedidos y por las ventas de
              un producto concreto.
            </p>

            <h3>AUTOMATIZA PROCESOS DE GESTIÓN POR WHATSAPP</h3>
            <p>
              Si en el día a día de tu negocio realizas operaciones recurrentes
              y consultas a bases de datos o envíos de informes, has de saber
              que esas tareas pueden ser automatizadas a través de Aplicaciones
              de chat y Whatsapp, no necesitarás estar en tu oficina ni
              conectarte en remoto, simplemente puedes enviar un mensaje y la
              tarea se realizará. En Rodapró somos expertos en sistemas de
              gestión y podemos ayudarte a hacer negocio.
            </p>
          </div>
          <img src={img1} alt="selbotpro para WhatsApp" />
        </section>
      </main>
      <Contacto />
      <Footer />
      <CookiesAcept />
    </div>
  );
}
