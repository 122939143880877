import React from "react";
import "./houndPro.css";
import Contacto from "../../components/contacto/contacto";
import CookiesAcept from "../../components/cookiesAcept/cookiesAcept";
import Footer from "../../components/footer/footer";
import img1 from "./imgHoundPro1.webp";
import logoHoundPro from "./houndproLogo.svg";

export default function HoundPro() {
  return (
    <div>
      <div className="houndpro">
        <header>
          <img src={logoHoundPro} alt="Logo houndpro" />
          <h1>Encuentra Clientes, rastrea Productos, Analiza tu competencia</h1>
        </header>
      </div>
      <main className="houndpro">
        <section className="houndpro-administrador">
          <div>
            <h2>¿Puedo obtener listados de empresas de Google?</h2>
            <p>
              Sí, es posible gracias a HoundPró, y no solo eso, también podemos
              obtener datos como productos, imagenes o texto de otras webs.
            </p>

            <h3>LISTADO DE CLIENTES POTENCIALES</h3>
            <p>
              Indiscutiblemente, hoy en día, la información está en Google y si
              ofreces servicios a empresas de determinados sectores, esos
              posibles clientes también están en Google, con nuestro servicio
              HoundPró podemos encontrar en poco tiempo un listado de empresas
              por sector empresarial o región.
            </p>

            <h3>RASTREO DE PRODUCTOS</h3>
            <p>
              Si necesitas un listado de productos extraídos de webs como Amazon
              o AliExpress estás de suerte, con el servicio HoundPró puedes
              obtener ese listado, con características y detalles que necesites.
              Esto puede ahorrarte mucho tiempo si estás realizando esta tarea
              manualmente.
            </p>

            <h3>ANÁLISIS DE COMPETENCIA</h3>
            <p>
              ¿Cómo puedes ser más competitivo? Para responder a esta pregunta,
              lo primero que debes conocer es contra quién estás compitiendo, un
              listado detallado de los productos, precios y otros detalles de
              los e-commerce de tu competencia sería de mucha utilidad, la
              información es poder ¡No te quedes atrás y analiza a tu
              competencia! posiblemente ellos te están analizando a ti.
            </p>
          </div>
          <img src={img1} alt="houndpro para WhatsApp" />
        </section>
      </main>
      <Contacto />
      <Footer />
      <CookiesAcept />
    </div>
  );
}
