import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";

export default function Footer() {
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
    <footer className="footerContainer" onClick={scrollToTop}>
      <div className="footerContainer-LogoRodapro">
          <h2>RODAPRÓ</h2>
      </div>
      <div className="footerContainer-MenuNavBar">
        <a className="footerContainer-MenuNavBar-OptionNavBar">
          <Link to={"/politica-de-privacidad"} >
            Politica de privacidad
          </Link>
        </a>
        <a className="footerContainer-MenuNavBar-OptionNavBar">
          <Link to={"/politica-de-cookies"} >
            Politica de Cookies
          </Link>
        </a>
      </div>
    </footer>
  );
}
