import React from "react";
import { useState } from "react";
import ordenador from "./imagenesDesarrolloDeAplicaciones/ordenadorDesarrolloDeAplicaciones.png";
import binario from "./imagenesDesarrolloDeAplicaciones/binario.png";
import candado from "./imagenesDesarrolloDeAplicaciones/candado.png";
import codigo from "./imagenesDesarrolloDeAplicaciones/codigo.png";
import codigoFondo from "./imagenesDesarrolloDeAplicaciones/codigoFondo.png";
import diagrama from "./imagenesDesarrolloDeAplicaciones/diagrama.png";
import llave from "./imagenesDesarrolloDeAplicaciones/llave.png";
import selector from "./imagenesDesarrolloDeAplicaciones/selector.png";
import "./imagenDesarrolloDeAplicaciones.css";

export default function ImagenDesarrolloDeAplicaciones() {
  return (
    <figure className="containerImagen">
      <div className="containerImagenesDesarrolloDeAplicaciones">
        <div className="mascaraDesarrolloDeAplicaciones"></div>
        <img className="ordenadorDesarrolloDeAplicaciones" alt="ordenadorDesarrolloDeAplicaciones" src={ordenador}/>
        <img className="binario" alt="binario" src={binario}/>
        <img className="candado" alt="candado" src={candado} />
        <img className="codigo" alt="codigo" src={codigo} />
        <img className="codigoFondo" alt="codigoFondo" src={codigoFondo}/>
        <img className="diagrama" alt="diagrama" src={diagrama} />
        <img className="llave" alt="llave" src={llave} />
        <img className="selector" alt="selector" src={selector} />
      </div>
    </figure>
  );
}
